export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyCPNg5FVikc2-QLFIvMmr_1XI7utxySpnQ',
    authDomain: 'like-pizzas.firebaseapp.com',
    projectId: 'like-pizzas',
    storageBucket: 'like-pizzas.appspot.com',
    messagingSenderId: '1098307954730',
    appId: '1:1098307954730:web:87a251cc3369bbaf94bb53'
  }
};

export const notificationKey = 'key=AAAA_7hBaCo:APA91bED2_Vxib-3emkAXjQSoWleHBeWJJfJ9im_471rHJp8IAuPyuhyAPtEo1_SvnWlYEwd_tSzvuc9zd0L4VNGACTndGL9hL5Ez-TpsKGqRD6qWqjwIIZzLwNfhDWJR1HhzxgFQyuP';

export const adminCredentials = {
  email: 'igor@email.com',
  password: '123456'
};
